<template>
  <v-row>
    <v-col>
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :headers="table.headers"
            :items="allLinks"
            :search="table.search"
          >
            <template v-slot:top>
              <v-autocomplete
                label="Member Name"
                :items="allMembers"
                item-text="name"
                item-value="website_id"
                v-model="table.search"
              ></v-autocomplete>
            </template>

            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | formatDate }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-menu left>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon v-on="on" v-bind="attrs">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" link @click="editData(item)">
                        <v-icon>mdi-file-edit</v-icon>
                      </v-list-item>
                    </template>

                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        v-on="on"
                        link
                        @click="deleteConfirmation(item)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-list-item>
                    </template>

                    <span>Delete</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="edit.dialog" width="800" persistent>
      <v-card :loading="edit.loading" :disabled="edit.loading">
        <v-toolbar flat>
          <v-toolbar-title>Edit Member Link</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="edit.data">
          <v-form ref="editLink">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Member Name"
                  :items="allMembers"
                  item-text="name"
                  item-value="id"
                  v-model="edit.data.member_id"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="App Name"
                  :items="edit.appcategory"
                  v-model="edit.data.app_name"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-textarea label="URL" v-model="edit.data.url"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn
              color="indigo accent-2"
              :loading="edit.loading"
              @click="saveEdit"
            >
              <span class="white--text">save</span>
              <v-icon right color="white">mdi-content-save</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="600">
      <v-alert class="mb-0" dark prominent icon="mdi-alert" color="warning">
        <v-row no-gutters class="align-center">
          Are you sure you want to delete this link?
          <v-spacer></v-spacer>
          <v-btn
            text
            :loading="del.loading"
            :disabled="del.loading"
            @click="handleDelete"
          >
            <span>ya</span>
          </v-btn>
          <v-btn depressed class="ml-2" @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      table: {
        search: null,
        headers: [
          { text: "Member Name", value: "member.name" },
          { text: "Website ID", value: "member.website_id" },
          { text: "App Name", value: "app_name", sortable: false },
          { text: "URL", value: "url", sortable: false },
          { text: "Last Update", value: "updated_at" },
          { text: "Action", value: "action", sortable: false },
        ],
      },
      edit: {
        data: null,
        loading: false,
        dialog: false,
        appcategory: [
          "Tokopedia",
          "Bukalapak",
          "Shopee",
          "Lazada",
          "Facebook",
          "Instagram",
          "TikTok Shop",
          "WhatsApp",
        ],
      },
      del: {
        data: null,
        dialog: false,
        loading: false,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateLink", "deleteLink"]),
    editData(item) {
      //   console.log(item);
      this.edit.data = item;
      this.edit.dialog = true;
    },
    saveEdit() {
      let data = this.edit.data;
      this.edit.loading = true;

      if (this.updateLink(data)) {
        this.edit.loading = false;
        this.showNotification("success");
      } else {
        this.edit.loading = false;
        this.showNotification("error");
      }
    },
    deleteConfirmation(item) {
      this.del.data = item;
      this.del.dialog = true;
    },
    handleDelete() {
      let data = this.del.data;
      // console.log(data)
      this.del.loading = true;

      if (this.deleteLink(data)) {
        this.del.loading = false;
        this.del.dialog = false;
        this.showNotification("success");
      } else {
        this.del.loading = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.text = "Successfully Updated";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.text = "Error Occured";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allLinks", "allMembers"]),
  },
  filters: {
    formatDate: (value) => {
      return moment(value).format("Do MMM YYYY");
    },
  },
};
</script>